import React from 'react';
import { useStyletron } from 'baseui';
import { Grid, Cell } from 'baseui/layout-grid';
import { Button } from 'baseui/button';

export default function Footer() {
  const applyNow = () => {
    // @todo include utm params
    window.location.href = 'https://extra.app/application';
  }

  return (
    <footer className="footer">
      <Outer>
        <Grid>
          <Cell span={8}></Cell>
          <Cell span={4}><Button onClick={applyNow}>Apply Now</Button></Cell>
        </Grid>
      </Outer>
    </footer>
  );
}

type OuterProps = {
    children: React.ReactNode;
}

const Outer = (props: OuterProps) => {
  const [css, theme] = useStyletron();

  return (
    <div
      className={css({
        color: theme.colors.accent100,
      })}
    >
      {props.children}
    </div>
  );
};