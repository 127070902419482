import React from 'react';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import { LightTheme, BaseProvider } from 'baseui';

import Quiz from './components/Quiz';

import './App.css';
import Footer from './components/Footer';

const engine = new Styletron();

function App() {
  return (
    <React.Fragment>
      <StyletronProvider value={engine}>
        <BaseProvider theme={LightTheme}>
          <div className="content">
            <div className="content-inside">
              <Quiz />
            </div>
          </div>
          <Footer />
        </BaseProvider>
      </StyletronProvider>
    </React.Fragment>
  );
}

export default App;
