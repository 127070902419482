import React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import logo from './extra-logo-black.svg';

import Overview from './Overview';
import CreditScore from './CreditScore';

function Quiz() {
    return (
      <Grid>
        <Cell span={8}>
            <img src={logo} alt="Extra Logo"></img>
        </Cell>
        <Cell span={12}>
            <Overview />
        </Cell>
        <Cell span={8}>
          <CreditScore />
        </Cell>
      </Grid>
    );
}

export default Quiz