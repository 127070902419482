import React from 'react';
import ReactDOM from 'react-dom/client';
import { Helmet } from 'react-helmet';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import packageJson from '../package.json';

// Initialize Datadog RUM
import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
    applicationId: 'e32c03e0-aa35-45ee-b8d7-e91611455b77',
    clientToken: 'pub9a7d7dc016fad1bc62714985f66a9958',
    service:'extra-for-me',
    env:'prod',
    version: packageJson.version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel:'mask-user-input'
});

datadogRum.startSessionReplayRecording();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Helmet>
      <title>Extra - For Me?</title>
      <link rel="canonical" href="https://extrafor.me" />
    </Helmet>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
