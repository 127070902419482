import React, { useState } from 'react';
import { Slider } from 'baseui/slider';
import { Heading, HeadingLevel } from 'baseui/heading';
import { Radio, RadioGroup } from 'baseui/radio';
import { ParagraphLarge } from 'baseui/typography';

const CreditScore = () => {
    const [knowsCreditScore, setKnowsCreditScore] = useState('')
    const [value, setValue] = useState([450]);

    console.log(value);
    return (
      <HeadingLevel>
        <Heading>Do you know your credit score?</Heading>
        <RadioGroup
          align="horizontal"
          name="know-your-credit-score"
          onChange={ e => setKnowsCreditScore(e.currentTarget.value)}
          value={knowsCreditScore}
        >
          <Radio value="yes">Yes</Radio>
          <Radio value="no">No</Radio>
        </RadioGroup>

        { knowsCreditScore === 'no' &&
            <ParagraphLarge>
                That's ok!
                Extra does not do a credit check when you sign up.
                Extra gives cardholders free access to their credit score in the app.
                So there is value in joining Extra to find out how your credit is and start on your journey to improve it.
            </ParagraphLarge>
        }

        { knowsCreditScore === 'yes' &&
            <>
            <Heading>What is your current credit score?</Heading>
            <ParagraphLarge>
                This can be a rough guess, it just helps us assess how much Extra will help you.
                Extra helps people with all credit scores but is especially good at helping those with lower scores.
                Extra does not do a credit check when you sign up so a bad score should not stop you from applying.
                In fact, Extra can likely raise your score more if your is low today!
            </ParagraphLarge>
            <Slider
              min={300}
              max={850}
              value={value}
              onChange={({ value }) => value && setValue(value)}
            />
            </>
        }
      </HeadingLevel>
    );
}

export default CreditScore;