import React from 'react';
import { MessageCard } from 'baseui/message-card';
import extracardHeader from './extracard-header.webp';

function Overview() {
    return (
        <MessageCard
            heading="Overview"
            onClick={() => {}}
            paragraph="Extra is the first debit card that allows you to build credit. But you may be wondering, is Extra for me? This fun and easy quiz should give you an answer!"
            image={{ src: extracardHeader,
                     ariaLabel: "Extra Card Header" }}
         />
    );
}

export default Overview;